.wpcf7-form {
  label {
    font-size: 18px;
    font-weight: 500;
    color: $main-gray-dark;
    width: 33%;
    display: inline-block;
    padding-right: 18px;
    select {
      margin: 0 0 1rem;
    }
    &:nth-last-child(1){
      width: 100%;
    }
  }
  .grund {
    font-weight: 500;
    color: $main-gray-dark;
  }
  select, input, textarea {
    border: 0;
  }
  .button {
    text-transform: uppercase;
    padding: 11px 66px;
    font-size: 18px;
  }
  @include breakpoint(small only) {
    label {
      display: block;
      font-size: 16px;
      width: 100%;
      padding-right: 0;
    }
    .wpcf7-list-item {
      margin: 0;
    }
    .wpcf7-list-item-label {
      font-size: 14px;
    }
    input.button {
      width: 100%;
    }
  }

}
