/* ==========================================================================
   #FOOTER
   ========================================================================== */

footer {
  background: $main-green;
  padding: 40px 0;
  > .grid-container {
    @include breakpoint(small only) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  h1, h2, h3, h3, h4, h5, h6, strong {
    color: $white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  span, p, a {
    color: $white;
    margin-bottom: 4px;
    @include breakpoint(medium only) {
      max-width: 60%;
    }
  }
  ul {
    list-style: none;
    margin-left: 0;
    margin-top: 0 !important  ;
    padding-left: 1em;
    text-indent: -1em;
    li {margin-bottom: 0;}
    a {color: $white; font-size: 14px; }
  }
  img {
    max-width: 100% !important;
  }

  @include breakpoint(large) {
    .cell {
      padding: 0 20px;
      &:nth-child(1) {
        padding-left: 0;
      }
      &:nth-last-child(1) {
        padding-right: 0;
      }
      > h3 {
        margin-top: rem-calc(42);
      }
      > ul {
        margin-top: rem-calc(36);
      }
    }
  }
  @include breakpoint(medium down) {
    padding: 0;
    ul li {
      margin-bottom: 0;
      a {
        font-size: 14px;
      }
    }
    .grid-x > .cell {
      padding: 40px 0 30px 0;

    }
  }

  .praxis {
    padding: 15px 0;
    a{
      display: block;
    }
  }
  .email {
    padding-top: 15px;
  }
}
