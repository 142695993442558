.image_text {
  .custom_image img {
    padding: 45px;
    padding-left: 0;
  }

  .custom_text {
    padding-left: 45px;
  }

  @include breakpoint(medium down){
    .custom_image img {
      padding: 0;
      padding-bottom: 15px;
    }
    .custom_text {
      padding: 0 20px;
    }
  }
  @include breakpoint(medium only){
    .custom_text {
      padding: 0;
    }
  }
}
