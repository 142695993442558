/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 0.8125rem; /* [1] */ /* 13px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  //padding: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @include bp(medium) {
    font-size: 0.875em; /* 14px */
  }

  @include bp(large) {
    font-size: 0.9375em; /* 15px */
  }

  @include bp(full) {
    font-size: 1em; /* 16px */
  }
}

html, body {
  overflow-y: initial;
  margin: 0;
}

header, footer {
  flex-shrink: 0;
}

main {
  flex: 1 0 auto; /* Use 1 0 auto instead of just 1 for backwards compatibility with other browsers */
  > section {
    overflow-x: hidden;
    address, h1, h2, h3, h4, h5, h6, blockquote, p, pre, dl, ol, ul, figure, hr, table, fieldset {
      margin-bottom: rem-calc(36);
    }
  }
  .flex-content .grid-container {
    @include breakpoint(small only) {
      padding: 0; // resetting double grid-container paddings
    }
  }
}

address, h1, h2, h3, h4, h5, h6, blockquote, p, pre, dl, ol, ul, figure, hr, table, fieldset {
  -ms-word-break: break-word;
  word-break: break-word;

  //-webkit-hyphens: auto;
  //-moz-hyphens: auto;
  //hyphens: auto;
}

.row {
  max-width: 1180px;
}
.overflow-hidden-mobile {
  @include breakpoint (medium down) {
    overflow: hidden !important;
  }
}

.overflow-hidden {
    overflow: hidden !important;
}

.object-fit-fallback {
  width: 100%;
  min-height: 100%;
  height: auto;
}

.space-filler {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.grid-container {
  @include breakpoint(small only) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (min-width: 1500px) {
  .grid-container {
    max-width: 1400px;
  }
}

.no-padding-small {
  @include breakpoint(small only) {
    padding: 0;
  }
}

.smaller-padding {
  @include breakpoint(small only) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.align-right-large {
  @include breakpoint(large) {
    justify-content: flex-end !important;
  }
}

//.cookie-notice-container {
//  #cn-notice-text  {
//    @include breakpoint(large) {
//      line-height: 33px;
//    }
//    a {
//      font-size: 14px;
//    }
//
//  }
//  #cn-accept-cookie {
//    padding: 5px 20px;
//    background: none;
//    outline: none;
//    border: 1px solid;
//    border-radius: 0;
//    margin-top: -1px;
//    box-shadow: none;
//    text-transform: uppercase;
//  }
//}
