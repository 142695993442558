.luum-slider {
  position: relative;
  z-index: 1;

  .slide-box {
    -webkit-transition: opacity 200ms;
    -moz-transition: opacity 200ms;
    -ms-transition: opacity 200ms;
    -o-transition: opacity 200ms;
    transition: opacity 200ms;
    opacity: 0;
    background-size: cover;
    position: absolute;
    width: 100%;
    min-height: 100%;
    &.active {
      opacity: 1;
    }
    .slide-content {
      max-width: 750px;
      width: 70%;
      min-width: 300px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    h2 {
      font-weight: 800;
      font-size: 40px;
      text-transform: uppercase;
    }
    p {
      font-weight: 300;
      font-size: 24px;
    }
    p.slide-arzt {
      font-weight: 700;
    }
    h1,h2,h3,h4,h5,p,span,a {
      position: relative;
      z-index: 100;
      color: $main-green;
      padding: 2px 10px;
      @include breakpoint(medium down) {
        font-size: 25px;
        padding: 0 7px;
        -ms-word-break: break-word;
        word-break: break-word;

        //-webkit-hyphens: auto;
        //-moz-hyphens: auto;
        //hyphens: auto;
      }
      &.main-color {
        background: $main-green;
        color: $white;
      }
    }
    .slide-corner {
      position: absolute;
      width: 500px;
      max-width: 45%;
      height: 100%;
      z-index: 99;
      top: 0;
      &.corner-left {
        left: 0;
        right: auto;
      }
      &.corner-right {
        right: 0;
        left: auto;
      }
    }
  }

  .slide-width {
    width: 100%;
    height: 100%;
    text-align: center;
    .slide-list {
      display: block;
      position: absolute;
      bottom: 20px;
      z-index: 100;
      max-width: 50%;
      text-align: center;
      left: 0;
      right: 0;
      margin: 0 auto;

      .slide-pin {
        cursor: pointer;
        background: $main-green;
        text-indent: -9999px;
        width: 15px;
        height: 15px;
        outline: 0;
        left: 0;
        position: relative;
        border-radius: 100%;
        -webkit-transition: border 200ms;
        -moz-transition: border 200ms;
        -ms-transition: border 200ms;
        -o-transition: border 200ms;
        transition: border 200ms;
        display: inline-block;
        right: 0;
        //border: solid grey 2px;
        margin: 15px;

        &.active {
          background: $white;
          //border: solid transparent 2px;
        }
      }
    }
  }

  .slide-content.hide-for-large {
    background: white;
    h1,h2,h3,h4,h5,h6,p,a,span {
      color: $main-green;
      width: 100%;
      padding: 10px 1.5rem;
      margin: 0;
      @include breakpoint(small only) {
        padding: 10px;
      }
    }
    h2 {
      padding-top: 20px;
    }
  }
}

.underslider-text {
  width: 97%;
  max-width: 1180px;
  margin: 0 auto;
  color: white;
  min-height: 50px;
  font-size: 18px;
  line-height: 50px;
  @include breakpoint(medium down) {
    width: 100%;
    padding: 10px 1.5rem;
    line-height: 22px;
  }
  @include breakpoint(small only) {
    padding: 10px;
  }
  p, a {
    color: $color-white;
    display: inline;
    margin: 0;
    &.uppercase, &.string {
      font-weight: 500;
    }
    &.uppercase {
      padding-right: 15px;
      font-size: 18px;
    }
  }
}
