.custom_text {
  color: $main-gray-dark;
  @include breakpoint(small only) {
    padding-left: 20px;
    padding-right: 20px;
  }
  h1,h2,h3, {
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 5px 0;
    padding-top: 30px;
    @include breakpoint(small only) {
      font-size: 7vw;
    }
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    @include breakpoint(small only) {
      font-size: 6vw;
    }
  }
  h5 {
    font-size: 18px;
    font-weight: 500;
    @include breakpoint(small only) {
      font-size: 5vw;
    }
  }
  h6 {
    font-size: 14px;
    font-weight: 300;
    color: $main-gray-dark;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: $main-gray-dark;
  }
  ul {
    font-size: 18px;
    margin-left: 1.5rem;
    margin-top: -20px;
    li{
      list-style: none;
      &:before{
        content: '';
        width: 6px;
        display: inline-block;
        height: 6px;
        background: $main-green;
        position: absolute;
        margin-top: 12px;
        margin-left: -1.5rem;
        border-radius: 50%;
      }
    }
  }
  button, .button {
    font-size: 18px;
  }
  @include breakpoint(small only) {
    p,a {
      font-size: 16px;
    }
    button, .button {
      font-size: 18px;
    }
  }
  p a, li a {
    font-size: unset;
  }
}
.custom_tabs {
  text-align: center;
  width: 100%;
  //position: relative;
  position: absolute;
  padding: 0 10px;
  left: 0;
  white-space: nowrap;
  overflow: auto;
  background: white;
  //&::after {
  //  content: '';
  //  z-index: 1;
  //  height: 100%;
  //  position: absolute;
  //  background: white;
  //  left: -50%;
  //  width: 200%;
  //  top: 0;
  //}
  &.main-color { .button {color: white;} &::after {background: $main-green;} }
  &.gray-color { &::after {background: $color-very-light-grey;} }
  .button.tab {
    background: none;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    outline: 0;
    border: 0;
    line-height: 30px;
    padding: 0 7px;
    margin: 40px 10px;
    color: $main-gray-dark;
    border-bottom: 2px solid transparent;
    @include breakpoint(medium down) {
      margin: 20px 0;
    }
    &.active {
      border-bottom: 2px solid $main-green;
      -webkit-transition: border-bottom-color 200ms;
      -moz-transition: border-bottom-color 200ms;
      -ms-transition: border-bottom-color 200ms;
      -o-transition: border-bottom-color 200ms;
      transition: border-bottom-color 200ms;
    }
  }
}

.flex-content .custom_text p > img {
  //border: 1px solid $color-very-light-grey;
  max-width: 100%;
}
