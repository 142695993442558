.praxis-kontakt {
  padding: 70px;
  max-width: 450px;
  margin: 0 auto;
  @include breakpoint(medium down) {
    padding: 30px 20px;
    max-width: unset;
  }

  p {
    color: $main-gray-dark;
    font-size: 18px;
  }
  p.p-sprechstunde {
    font-weight: 700;
    font-size: 24px;
    span {
      float: right;
    }
  }
  p.p-praxis {
    color: $main-green;
  }

  .button {
    padding: 11px 66px;
    font-size: 18px;
    display: block;
  }

  hr {
    width: 51%;
    border-bottom: 2px solid $main-green;
    padding: 10px 0;
    @include breakpoint(small only){
      width: 100%;
    }
  }

  .phone-img {
    font-size: 24px;
    font-weight: 500;
    &:before {
      width: 26px;
      height: 52px;
    }
  }
}
