.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: -13px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  &.active {
    z-index: 24;
    position: relative;
  }
}
.hamRotate.active {
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.hamRotate180.active {
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
}
.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke:white;
  stroke-width:5.5;
  stroke-linecap:round;
}
.ham .top {
  stroke-dasharray: 40 139;
}
.ham .bottom {
  stroke-dasharray: 40 180;
}
.ham.active .top {
  stroke-dashoffset: -98px;
}
.ham.active .bottom {
  stroke-dashoffset: -138px;
}

.custom_button {
  .button {
    margin: 0;
  }
  @include breakpoint (small only) {
    padding: 0 20px;
  }
  @include breakpoint (medium only) {
    padding: 0 10px;
  }
}


section {
  &.gray-section {
    background: $color-very-light-grey;
  }
  &.main-section {
    background: $main-green;
    span, p, h1, h2, h3, h3, h4, h5 {color: $white;}
  }
  &.main-2-section {
    background: $main-green;
    color: $white;
  }
  &.white-section {
    background: $white;
  }
}

.bullet {
  border: solid $dark-gray;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 1px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

li .bullet {
  top: -4px;
  position: relative;
  border-width: 0 2px 2px 0;
}

.color-main-border {
   border-color: $main-green;
 }
.color-main {
  color: $main-green;
}
.color-main2-border {
  border-color: $main-green;
}

.color-main2 {
  color: $main-green;
}

.color-white-border {
  border-color: #fff;
}

.phone-img {
  line-height: normal;
  position: relative;
  padding-left: 2em;
  &::before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -2px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background-image: url(../assets/images/icon_phone.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  &.button {
    padding: 0.4em 1em 0.4em 2em;

    &::before {
      margin-top: -1px; /* visual center */
      left: 6%;
    }
  }
}
.header_title {
  min-width: 40%;
}
.header_title .phone-img {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  &::before {
    width: 13px;
    height: 26px;
    left: auto;
    margin-left: -20px;
  }
}

.phone-green {
  &::before {
    background-image: url(../assets/images/icon_phone-green.svg);
  }
  &:hover {
    color: $main-green;
    opacity: 0.5;
  }
}

.error404 {
  text-align: center;
  font-size: 2rem;
}

.medium-text-center {
  @include breakpoint(medium down) {
    text-align: center;
  }
}
