/* ==========================================================================
   #HEADER
   ========================================================================== */
#wpadminbar {
  bottom: 0 !important;
  top: auto !important;
  position: fixed !important;
}

header {
  position: fixed;
  top: 0;
  background: white;
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 30;
  &.nav-up {
    top: -40px;
  }
  .c-header {
    border-bottom: 1px solid $color-border;
    margin-bottom: 2em;
    padding-bottom: 1em;
  }

  .header_title_div {
    z-index: 22;
    position: relative;
    background: $main-green;
    .text-right {
      position: relative;
      .string {
        position: absolute;
        top: 50%;
        right: 0;
        font-size: 13px;
        font-weight: 700;
        transform: translateY(-50%);
        color: white;
      }
    }

    p {
      padding: 0.7rem 0;
      margin: 0;
      color: $white;
      @include breakpoint(medium down) {
        margin: 0 auto;
      }
    }

  }

  .header_menu_div {
    position: relative;
    z-index: 20;
    padding: 0.5rem 0;
    .phone {
      display: none;
    }
    svg {
      display: inherit;
      float: right;
    }
    .logotype {
      margin: 20px 0;
    }
    .menu {

      .menu-item {
        margin: 0;
        @include breakpoint(medium down) {
          padding: 18px 0;
          border-bottom: 1px solid $color-very-light-grey;
          &:nth-last-child(1) {
            border-bottom: none;
          }
        }

        a {
          text-transform: uppercase;
          padding: 12px 2px 7px 2px;
          font-weight: 700;
          @include breakpoint(medium down) {
            padding: 2px 0 0 0;
          }
          font-size: 15px;
          color: $main-gray-dark;
          border-bottom: 2px solid transparent;
          margin: 0 rem-calc(10);
          &:hover {
            border-bottom:  2px solid rgba($main-green, 0.3);
            @include breakpoint(medium down) {
              border-bottom: 2px solid transparent;
              opacity: 0.8;
            }
            -webkit-transition: all 200ms;
            -moz-transition: all 200ms;
            -ms-transition: all 200ms;
            -o-transition: all 200ms;
            transition: all 200ms;
          }
          @include breakpoint(medium down) {
            font-family: $font-main, sans-serif;
            font-size: 22px;
            font-weight: 700;
            color: $main-green;
          }
        }

      }

      li.is-dropdown-submenu-parent {
        .is-dropdown-submenu {
          min-width: 240px;
        }
        @include breakpoint(medium down) {
          display: block;
          & > a {
            padding-bottom: 18px;
            padding-top: 18px;
          }
          & > .is-dropdown-submenu {
            left: 0 !important;
            position: relative !important;
            padding: 0 !important;
            li {
              margin-bottom: 0;
            }
          }
        }


        ul {
          border: 2px solid $main-green;
          margin-top: 33px;
          right: auto !important;
          left: 18px !important;
          li {
            border: 2px solid $main-green;
            @include breakpoint(medium down) {
              border: 0;
            }
          }
          a {
            margin: 0;
            border-bottom: none;
            padding: 10px 20px;
            border-bottom: none;
            &:hover {border: none}

          }
          @include breakpoint(medium down) {
            border: none;
            margin: 0;
            a {
              width: 100%;
              border: none;

            }
            li {
              margin-bottom: 0;
            }
          }

        }
      }



      .current_page_item {
        a {
          border-bottom: 2px solid $main-green;
          @include breakpoint(medium down) {
            border-bottom: 2px solid transparent;
            color: $main-green;
          }
        }
      }
    }

    &.active {
      @include breakpoint(medium down) {
        overflow: auto;
        padding: rem-calc(22) 0 rem-calc(22) 0;
        max-height: calc(100vh - 54px);
        //margin-bottom: -60px;

        > .grid-x {
          padding: 0;
        }
        &::after {
          content: '';
          position: fixed;
          width: 100%;
          height: 100%;
          top: 100px;
          background: $color-very-light-grey;
        }
        .logotype {
          display: none;
        }

        .phone {
          display: block;
          position: relative;
          margin-top: 40px;
          z-index: 2;
        }

        .menu {
          display: block !important;
          position: relative;
          right: 0;
          background: $white;
          width: 100%;
          height: 100%;
          z-index: 21;
          text-align: center;
          font-size: 28px;
          overflow: auto;
          .menu-item-has-children a {
            padding: 0;
          }

          li {
            justify-content: center;
            &:nth-last-child(1) {
              margin-bottom: rem-calc(22);
            }
          }
        }
      }
    }
  }
}

@include breakpoint(medium down) {

}

