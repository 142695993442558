.places {
  padding: 30px;
  margin: 0 auto;

  .p-image img {
    margin-top: 5px;
  }
  @include breakpoint(medium down) {
    padding: 0 20px;
    max-width: unset;
    p.p-praxis {
      margin: 0;
    }
    p.p-sprechstunde-title {
      margin-bottom: 5px;
    }
  }

  @include breakpoint(medium only) {
    padding: 0;
  }

  @include breakpoint(large){
    &.large-8 {
      padding-left: 0;
    }
    &:nth-child(1){
      padding-left: 0;
    }
    &:nth-last-child(1){
      padding-right: 0;
    }
  }

  p {
    color: $main-gray-dark;
    font-size: 18px;
    a {
      font-size: 18px;
    }
  }
  p.p-sprechstunde-title, p.p-praxis {
    font-weight: 600;
  }
  p.p-sprechstunde {
    font-weight: 700;
    font-size: 24px;
    span {
      float: right;
    }
  }

}
