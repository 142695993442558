/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

/* ==========================================================================
	Layout
/* ========================================================================== */

h1, h2, h3, h4, h5, h6, p, a {
  font-family: $font-main, sans-serif;
  color: $main-green;

}

ul, ul li, ul li > a {
  font-size: rem-calc(20);
}

body {
  font-family: $font-main, sans-serif;
  font-weight: regular;
  a {
    text-decoration: none;
    color: $main-green;
  }

}

p, a, p.small, footer p, footer a {
  font-size: 14px;
}

b, strong {
  font-weight: 600;
}

h1 {
  font-size: 50px;
  line-height: 1.2;

  @include breakpoint(medium only) {
    font-size: 42px;
  }
  @include breakpoint(small only) {
    //font-size: 36px;
    font-size: 7vw;
  }
}

h2 {
  font-size: 40px;
  line-height: 1.2;

  @include breakpoint(small only) {
    font-size: 22px;
  }

}

h3, h4 {
  font-size: 28px; // Not in use

}

h5 {
  font-size: 20px;

  @include breakpoint(small only) {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
}

.uppercase {
  text-transform: uppercase;
}

.bolder {
  font-weight: 600;
}

.n-green {
  color: $main_green;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  &.uppercase {
    letter-spacing: 0.125em;
    text-align: center;
  }
}
