.twitter-feed {
  .text-center {
    margin-top: 20px;
  }

  .tweet {
    padding: 17px;
    a {
      display: block;
      background: white;
      padding: 14px 10px;
      color: $main-gray-dark;
    }
    .floating-image {
      display: inline-block;
      img {
        border-radius: 50%;
        width: 31px;
      }
    }
    .floating-name {
      display: inline-block;
      margin-left: 10px;
      color: black;
      font-weight: 300;
    }
    .floating-date {
      display: inline-block;
      float: right;
    }
    .post-content {
      padding-top: 14px;
    }
  }
}
