.nav_block {
  padding: 22px;
  padding-top: 85px;
  @include breakpoint(large) {
    &:nth-child(1) {
      padding-left: 0;
    }
    &:nth-last-child(1) {
      padding-right: 0;
      h6 {
        hyphens: auto;
      }
    }
  }
  @include breakpoint(medium down) {
    text-align: center;
    padding: 10px 20px;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $color-very-light-grey;
      left: 0;
      margin-top: 10px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #eeeff3;
      left: 0;
      margin-top: -10px;
    }
  }
  .wrapper_card {
    height: auto;
    width: 100%;
    display: block;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    &.wrapper_padding {
      background: #fff;
      padding: 20px;
    }

    h6 {
      margin: 30px 0 25px 0;
      color: $main-green;
      min-height: 75px;
      text-transform: uppercase;
      @include breakpoint(medium down) {
        margin: 0;
        min-height: auto;
      }
    }
    p {
      color: $main-gray-dark;
      font-size: 14px;
      -ms-word-break: break-word;
      word-break: break-word;
      margin-bottom: 30px;
      //-webkit-hyphens: auto;
      //-moz-hyphens: auto;
      //hyphens: auto;
      .bullet {
        margin-right: 6px;
        position: relative;
        top: -3px;
      }
    }
    .nav_block_img {
      height: 150px;
      max-height: 9vw;
      overflow: hidden;
      background-position: center center;
      background-size: cover;
    }
    .nav_svg {
      background-color: $main-green;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .more {
      color: $main-green;
      -webkit-transition: opacity 200ms;
      -moz-transition: opacity 200ms;
      -ms-transition: opacity 200ms;
      -o-transition: opacity 200ms;
      transition: opacity 200ms;
    }
  }

}
.nav_parent {
  @include breakpoint(medium down) {
    padding: 50px 0;
  }
}
